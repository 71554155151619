export const getByUnidade = (axios, canal_codigo) => axios.get(`usuario/unidade/${canal_codigo}`)


export const addUser = (axios, payload) => axios.post(`usuario`, payload)

export const editUser = (axios, payload) => axios.put(`usuario`, payload)

export const getByCodigo = (axios, usuario_codigo) => axios.get(`usuario/${usuario_codigo}`)

export const alterarSenha = (axios, payload) => axios.post(`usuario/update/password`, payload)

export const resetPasswordByEmail = (axios, payload) => axios.post(`usuario/recuperacao/password`, payload)

export const countUsuarioByEmail = (axios, payload) => axios.post(`usuario/count`, payload)

export const countUsuarioByEmailUpdate = (axios, payload) => axios.post(`usuario/count/update`, payload)